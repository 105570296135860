button {
  box-shadow: none !important;
}

.btn-customPrimary {
  background-color: var(--primary-blue, rgba(38, 153, 251, 1));
  color: #fff;
  font-family: 'Montserrat-Regular';
  border-radius: 50px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn-customPrimary:disabled {
  background-color: gray;
}

.btn-customPrimary:active {
  background-color: var(--lighter-blue, rgba(38, 153, 251, 1));
}

.btn-customPrimarySmol {
  background-color: var(--lighter-blue, rgba(38, 153, 251, 1));
  color: #fff;
  font-family: 'Montserrat-Medium';
  line-height: 15px;
  border-radius: 5px;
  font-size: 12px;
}

.btn-customPrimarySmol:active {
  background-color: var(--primary-blue, rgba(38, 153, 251, 1));
}

.btn-customPrimarySmol-outline {
  background-color: transparent;
  color: var(--lighter-blue, rgba(38, 153, 251, 1));
  font-family: 'Montserrat-Medium';
  line-height: 15px;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid rgb(156, 156, 156);
}

.btn-customPrimarySmol-outline:active {
  color: var(--primary-blue, rgba(38, 153, 251, 1));
  background-color: #aaa;
}

.btn-customPrimaryWithShadow {
  background-color: var(--primary-blue, rgba(38, 153, 251, 1));
  color: #fff;
  font-family: 'Montserrat-Regular';
  width: 9.375rem;
  height: 2.25rem;
  font-size: 0.875rem;
  border-radius: 50px;
  box-shadow: 0px 6px 10px 0px var(--lighter-blue);
}

.btn-customPrimaryWithShadow:active {
  background-color: var(--lighter-blue, rgba(38, 153, 251, 1));
}

.btn-customSecondary {
  background-color: #fff;
  color: var(--primary-blue);
  border-radius: 50px;
  padding-top: 0;
  padding-bottom: 0;
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  border: 1px solid var(--primary-blue);
}

.btn-customSecondary:active {
  color: var(--lighter-blue);
  border: 1px solid var(--lighter-blue);
}

.btn-customLightBlue {
  background-color: var(--lighter-blue);
  color: #fff;
  font-family: 'Montserrat-Regular';
  border-radius: 10px;
  font-size: 12x;
  line-height: 15px;
  max-width: 128px;
}

.btn-customLightBlue:active {
  background-color: var(--primary-blue);
}

.btn-batchCustomNotSelected {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: 'Montserrat-Regular';
  border-radius: 10px;
  font-size: 12x;
  line-height: 15px;
  max-width: 128px;
}

.btn-batchCustomNotSelected:active {
  border: 1px solid rgba(58, 58, 58, 0.1);
}

.btn-customSkip {
  font-family: 'Montserrat-Regular';
  color: #fff;
  border-radius: 50px;
  background-color: #000;
  opacity: 0.5;
  height: 32px;
  font-size: 12px;
  padding-right: 16px;
  padding-left: 16px;
}

.btn-customSkip:active {
  background-color: #484848;
}

@media (min-width: 992px) {
  .btn-customSkip {
    height: 40px;
    font-size: 18px;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.btn-grayBackground {
  border-radius: 11px;
  background: #e9e9e9;
  color: #000;
  font-family: 'Montserrat-Regular';
  border-radius: 50px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn-grayBackground:disabled {
  background-color: gray;
}

.btn-grayBackground:active {
  background-color: #e9e9e9;
}

.btn-dashboardBlueOnWhite {
  border-radius: 5px;
  background-color: #fff;
  color: var(--lighter-blue, rgba(127, 196, 253, 1));
  font-family: 'Montserrat-Medium';
  font-size: 18px;
  line-height: 22px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
  height: 36px;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-dashboardBlueOnWhite:active {
  background-color: #ccc;
  color: var(--primary-blue, rgba(127, 196, 253, 1));
}

.btn-dashboardBlueOnWhite:focus {
  color: var(--lighter-blue);
}

.btn-dashboardHWletsGo {
  border-radius: 5px;
  background: linear-gradient(90deg, #fe979c, #feae96);
  color: #fff;
  font-family: 'Montserrat-Medium';
  font-size: 18px;
  line-height: 22px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
  height: 36px;
  padding-left: 20px;
  padding-right: 20px;
  width: 80%;
}

.btn-dashboardHWletsGo:active {
  background: linear-gradient(90deg, #bd6f73, #be8270);
}
@media screen and (max-width: 768px) {
  .btn-dashboardHWletsGo {
    width: 95%;
    height: 44px;
  }
}
@media screen and (max-width: 600px) {
  .btn-dashboardHWletsGo {
    width: 93%;
  }
}

.btn-courseBlueOnWhite {
  border-radius: 5px;
  background-color: #fff;
  color: var(--primary-blue);
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  line-height: 16px;
  height: 36px;
  width: 120px;
  padding: 0 10px 0 10px;
}

.btn-courseBlueOnWhite :active {
  background-color: #aaa;
  color: var(--lighter-blue);
}

.btn-courseBlueOnWhiteSmol {
  border-radius: 5px;
  background-color: #fff;
  color: var(--primary-blue);
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  line-height: 16px;
  height: 24px;
  width: 70px;
  padding: 0 10px 0 10px;
}

.btn-courseBlueOnWhiteSmol:active {
  background-color: #aaa;
  color: var(--lighter-blue);
}

.btn-noticeBoardPost {
  border-radius: 50px;
  border: 0.1px solid #707070;
  padding-right: 20px;
  padding-left: 20px;
  margin: 10px;
  min-width: 145px;
  width: max-content;
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  height: 36px;
}

.btn-noticeBoardPost:active {
  border: 0.1px solid #353535;
}

@media screen and (min-width: 1440px) {
  .btn-noticeBoardPost {
    height: 48px;
    font-size: 22px;
  }
}

.btn-dynamicOutline {
  border-radius: 5px;
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
  height: 32px;
  width: 32px;
  padding: 0;
}

.btn-dynamicOutline:active {
  border: 1px solid var(--lighter-blue);
  color: var(--lighter-blue);
}

.btn-unfocusedOutline {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.54);
  height: 32px;
  width: 32px;
  padding: 0;
}

.btn-unfocusedOutline {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.54);
  height: 32px;
  width: 32px;
  padding: 0;
}

.btn-unfocusedOutline:active {
  border: 1px solid rgba(90, 90, 90, 0.54);
  color: rgba(90, 90, 90, 0.54);
}

.btn-liveClasses {
  background-color: #fff;
  color: var(--primary-blue);
  border-radius: 50px;
  padding-top: 0;
  padding-bottom: 0;
  font-family: 'Montserrat-Regular';
  font-size: 14px;
}
.btn-liveClasses:active {
  background-color: #aaa;
  color: var(--lightest-blue);
}
@media screen and (min-width: 1440px) {
  .btn-liveClasses {
    font-size: 20px !important;
  }
}

.btn-uploadVideoButton {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 50px;
  padding-top: 0;
  padding-bottom: 0;
  font-family: 'Montserrat-Regular';
  font-size: 14px;
}

.btn-uploadVideoButton:active {
  background-color: #aaa;
}

.btn-boldText {
  background-color: #fff;
  color: var(--primary-blue);
  border-radius: 50px;
  padding-top: 0;
  padding-bottom: 0;
  font-family: 'Montserrat-Bold';
  font-size: 14px;
}

.btn-boldText:hover {
  color: var(--primary-blue);
}

.btn-boldText:active {
  color: var(--primary-blue);
}

// .btn-boldText:focus {
//   transition: none;
//   box-shadow: none !important;

// }

.btn-boldTextSecondary {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.54);
  border-radius: 50px;
  padding-top: 0;
  padding-bottom: 0;
  font-family: 'Montserrat-Bold';
  font-size: 14px;
}

.btn-boldTextSecondary:hover {
  color: var(--primary-blue);
}

.btn-boldTextSecondary:active {
  color: var(--primary-blue);
}

.btn-redBoldText {
  background-color: #fff;
  color: #ff0000;
  border-radius: 50px;
  padding: 0;
  font-family: 'Montserrat-Bold';
  font-size: 14px;
}

.btn-redBoldText:active {
  background-color: #aaa;
  color: #820000;
}

.btn-finishTest {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  color: #fff;
  background-color: var(--lighter-blue, rgba(127, 196, 253, 1));
  height: 1.5rem;
  width: 70px;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
}

.btn-finishTest:active {
  font-size: 14px;
  font-family: 'Montserrat-Medium';
  color: #fff;
  background-color: var(--lightest-blue, rgba(127, 196, 253, 1));
}

.btn-testBlueOnWhite {
  border: 0.5px solid $dashboardGrey;
  border-radius: 15px;
  height: 1.5rem;
  width: max-content;
  margin: 0.5rem;
  font-size: 0.75rem;
  font-family: $regular;
  padding: 3px 10px;
}

.active.btn-testBlueOnWhite {
  background-color: #fff;
  border-color: #fff;
}

.active.btn-testBlueOnWhite:active {
  background-color: #aaa;
  border-color: #aaa;
}

.btn-testBlueOnWhite:focus {
  outline: none;
  box-shadow: none;
}

.btn-testPallette {
  border: 0.5px solid $dashboardGrey;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 1.5rem;
  padding: 0;
  font-size: 0.75rem;
  font-family: $regular;
  margin: 0.5rem;
}

.btn-greenTest {
  background-color: #0f0;
  color: #fff;
}

.btn-greenTest:active {
  background-color: rgb(0, 108, 0);
}

.btn-purpleTest {
  background-color: #5e2d79;
  color: #fff;
}

.btn-purpleTest:active {
  background-color: #2d0b3e;
}

.btn-redTest {
  background-color: #ff0000;
  color: #fff;
}

.btn-redTest:active {
  background-color: #900101;
}

.btn-purpleGreenTest {
  background-color: #5e2d79;
  color: #fff;
  position: relative;
}

.btn-purpleGreenTest:active {
  background-color: #2d0b3e;
}

.btn-customTestSubmit {
  font-size: 10px;
  font-family: $medium;
  color: #fff;
  line-height: 1.25rem;
  background-color: var(--lighter-blue);
  border-radius: 50px;
  height: 36px;
  width: 100px;
  margin: 5px;
  padding: 0;
}

.btn-customTestSubmit:active {
  background-color: var(--primary-blue);
}

.btn-reportProblem {
  color: #ff0000de;
  background-color: #ffffff;
  font-family: 'Montserrat-Regular';
  font-size: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 3px 6px 3px 6px;
  margin-top: auto;
  margin-bottom: auto;
}

.btn-reportProblem:active {
  color: #950000de;
  background-color: #b9b9b9;
}

.btn-homeworkAdd {
  color: #ffffff;
  background-color: var(--primary-blue);
  height: 28px;
  width: 28px;
  border-radius: 14px;
  padding: 0;
  position: absolute;
}

.btn-homeworkAdd:active {
  background-color: var(--lighter-blue);
}

.btn-homeworkAddred {
  color: #ffffff;
  background-color: red;
  height: 28px;
  width: 28px;
  border-radius: 14px;
  padding: 0;
  position: absolute;
}

.btn-homeworkAddred:active {
  background-color: rgb(128, 1, 1);
}

.btn-homeworkAddToPaper {
  color: var(--primary-blue);
  font-size: 12px;
  line-height: 15px;
  font-family: 'Montserrat-Medium';
  border: 1px solid var(--primary-blue);
  border-radius: 14px;
  height: 28px;
  padding-left: 28px;
}

.btn-homeworkAddToPaper:active {
  color: var(--lighter-blue);
  border: 1px solid var(--lighter-blue);
}

.btn-homeworkAddToPaperred {
  color: red;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Montserrat-Medium';
  border: 1px solid red;
  border-radius: 14px;
  height: 28px;
  padding-left: 28px;
}

.btn-homeworkAddToPaperred:active {
  color: rgb(134, 0, 0);
  border: 1px solid rgb(134, 0, 0);
}

.btn-greenButtonLong {
  background-color: rgba(37, 230, 0, 1);
  border-radius: 5px;
  width: 100%;
  color: #fff;
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  line-height: 18px;
}

.btn-greenButtonLong:active {
  background-color: rgb(22, 128, 0);
}

.btn-primaryOutline {
  border-radius: 5px;
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
  font-family: 'Montserrat-Bold';
}

.btn-primaryOutline:active {
  border: 1px solid var(--lighter-blue);
  color: var(--lighter-blue);
}

.btn-redOutline {
  border-radius: 5px;
  border: 1px solid #ff0000de;
  color: #ff0000de;
  font-family: 'Montserrat-Bold';
}

.btn-redOutline:active {
  border: 1px solid #9a0000de;
  color: #9a0000de;
}

.btn-light:focus {
  outline: none;
  box-shadow: none;
}

.btn-JumboLogin {
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  font-family: 'Montserrat-Medium';
  height: 55px;
  width: 236px;
  background-color: var(--primary-blue);
  box-shadow: 0px 6px 10px 0px var(--lighter-blue);
  border-radius: 8000px;
  left: 0;
  right: 0;
  margin: 5% auto;
  margin-top: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btn-JumboLogin:active {
  background-color: var(--lighter-blue);
}

.btn-loginPrimary {
  background-color: var(--primary-blue);
  box-shadow: 0px 6px 10px 0px var(--lighter-blue);
  border-radius: 5px;
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  width: 100%;
}

.btn-loginPrimary:active {
  background-color: var(--lighter-blue);
}

/**************************************Progress Bar Styles ********************************/

.bg-testProgress {
  height: 0.5rem;
  background-color: var(--primary-blue);
  border-radius: 5px;
}
.testerProgressBar {
  width: 100%;
  height: 0.5rem;
  background-color: rgba(0, 0, 0, 0.11);
  border-radius: 5px;
}

@media (min-width: 1024px) {
  .btn-JumboLogin {
    margin-top: 100px;
  }
}
