.PageHeader {
  @import '../../../scss/bootstrap';

  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: $white;

  &__title {
    font-size: $spacer * 1.25;
    font-family: 'Montserrat-Medium';
    line-height: $spacer * 1.5;
    color: $headingGrey;
    width: 60%;
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
  &__input,
  &__input:focus {
    border: none;
    outline: none;
    width: 80%;
  }

  &__input::placeholder {
    font-family: 'Montserrat-Regular';
    color: $dashboardGrey;
  }

  &__text {
    font-size: 14px;
    font-family: $regular;
  }
}
.notFixedPageHeader {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
}

.Parent_of_all_Pageheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.PageHeaderChild1 {
  flex: 1;
  flex-basis: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
// .Child2{
//   padding: 0rem 4vw 0rem 0rem ;
//   box-sizing: border-box;
// }
